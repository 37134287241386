<template>
  <FocusTrap>
    <div class="card" id="mycard">
      <div class="page-header page-header-light" >
        <div class="page-header-content header-elements-lg-inline" >
          <div class="page-title d-flex" style="padding: 9px;">
            <h6>
              <i class="icon-list mr-2"></i> <span class="font-weight-semibold">Labours </span> - List
            </h6>
            <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
          </div>

          <div class="header-elements d-none">
            <form action="#">
              <div class="form-group">

                <div class="input-group input-group-feedback input-group-feedback-right wmin-lg-200">
                  <input id="txtsearch" type="text" class="form-control wmin-lg-200" placeholder="Search" autocomplete="off" style="padding-left: 20px;">
                  <div class="form-control-feedback">
                    <i class="icon-search4"></i>
                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>

      <div>
        <div class="table-responsive">
          <table id="labour-table"
                 class="table table-bordered table-columned"
                 data-search="false"
                 data-pagination="false"
                 data-show-refresh="false"
                 data-show-columns="false"
                 data-page-list="[10, 25, 50, 100, ALL]"
                 data-show-footer="false"
                 data-toggle="context"
                 data-target=".context-table">
            <thead>
            <tr>
              <th data-field="id" data-class="d-none">id</th>
              <th data-formatter="runningFormatter" data-width="75" >S.No</th>
              <th data-field="name" data-sortable="true">Name</th>
              <th data-field="mobile" data-sortable="true">Mobile</th>
            </tr>
            </thead>
            <tbody>
            </tbody>
            <tfoot>
            </tfoot>
          </table>
        </div>

        <!-- Context Menu -->
        <div id="context-menu" class="context-table">
          <div class="dropdown-menu">
            <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item" style="color: blue;"> <i class="icon-pencil3" ></i>Modify</a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item" style="color: red"> <i class="icon-blocked" ></i>Cancel</a>
          </div>
        </div>
        <!-- End of Context Menu -->

      </div>

      <FocusTrap>
        <vmodal name="labour_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="43%" :height="310" @closed="afterCloseWindow" >
          <LabourForm v-on:labour_window_closed="closeModal" v-bind:mylabour="labour" v-on:labour_saved="loadData" ></LabourForm>
        </vmodal>
      </FocusTrap>

      <FocusTrap>
        <vmodal name="filter_window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="575" :height="245" @opened="afterfilterwindow_visible" draggable=".window-header">
          <form>
            <div class="card">
              <div class="card-header header-elements-inline bg-primary" style="padding: 10px;">
                <h3 class="card-title">Filter</h3>
                <div class="header-elements">
                  <div class="list-icons">
                    <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
                  </div>
                </div>
              </div>

              <div class="card-body">

                <p/>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">From:</label>
                  <div class="col-md-3">
                    <input id= "txtfrom" ref= "txtfrom" type="date" class="form-control"  v-model="from_date" :max="upto_date" autofocus />
                  </div>

                  <label class="col-md-3 col-form-label">Upto</label>
                  <div class="col-md-3">
                    <input type="date" class="form-control"  v-model="upto_date" :min="from_date" />
                  </div>
                </div>


                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Ledgers:</label>
                  <div class="col-md-9">
                    <select id="cmbLedger" class="form-control" v-model="ledger_id" >
                      <option value="0" selected>All</option>
                      <option v-for="ledger in ledgers" v-bind:value="ledger.id">
                        {{ ledger.name }}
                      </option>
                    </select>
                  </div>
                </div>


                <div class="text-right">
                  <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Show Data<i class="icon-database-refresh ml-2"></i></button>
                </div>

              </div>
            </div>
          </form>
        </vmodal>
      </FocusTrap>

      <!-- Clickable menu -->
      <FocusTrap>
        <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="99">
          <li>
            <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
              <i class="fab-icon-open icon-plus3"></i>
              <i class="fab-icon-close icon-plus3"></i>
            </a>
          </li>
        </ul>
      </FocusTrap>
      <!-- /clickable menu -->

    </div>
  </FocusTrap>
</template>

<script>
import moment from 'moment-timezone'
import LabourForm from './LabourForm';
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'LabourView',
  components: {
    LabourForm
  },
  store,
  data () {
    return {
      mytable: {},
      labour : {"id":0,"type":1,"name":"","mobile":""},
      from_date : moment().format('YYYY-MM-DD'),
      upto_date : moment().format('YYYY-MM-DD'),
      ledger_id : 0,
    }
  },
  created () {

  },
  mounted () {
    const self = this;

    self.$data.from_date = moment().format('YYYY-MM-DD');
    self.$data.upto_date = moment().format('YYYY-MM-DD');


    self.$data.mytable = $('#labour-table');

    this.$data.mytable.bootstrapTable();
    $('[data-toggle="popover"]').popover();

    $('#labour-table').contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {
        let id = $(row.children('*')[0]).text();

        if ($(e.target).text() === 'Modify') {
          self.modifyVoucher(id);
        }else if ($(e.target).text() === 'Cancel') {
          self.cancelVoucher(id);
        }
      }
    });

    $('#txtsearch').keyup(function () {

      var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();

      $('#labour-table>tbody>tr').show().filter(function () {
        var text = $(this).text().replace(/\s+/g, ' ').toLowerCase()
        return !~text.indexOf(val)
      }).hide();

    });

    self.loadData();
    // self.$modal.show('filter_window');
  },
  methods:{
    showModal () {
      this.$modal.show('labour_window');
    },
    showFilterModal () {
      this.$modal.show('filter_window');
    },
    afterCloseWindow(){
      const self = this;
      self.labour = JSON.parse('{"id":0,"type":1,"name":"","mobile":""}');
    },
    closeModal () {
      this.$modal.hide('labour_window');
    },
    afterfilterwindow_visible() {
      this.$refs.txtfrom.focus();
    },
    loadData(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        credentials: 'same-origin',
        headers: userService.authHeader()
      };

      self.$modal.hide('labour_window');

      // self.$modal.hide('filter_window');
      $('#txtsearch').val('');

      $(self.$data.mytable).block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.labour = JSON.parse('{"id":0,"type":1,"name":"","mobile":""}');
      self.$data.mytable.bootstrapTable('load',[]);
      self.$data.mytable.bootstrapTable('showLoading');

      fetch(`${process.env.VUE_APP_ROOT_API}api/bms/labours`,requestOptions).then(userService.handleResponse).then(function (resp) {

        if(resp.ok) {

          if( _.isArray (resp.data)) {
            self.$data.mytable.bootstrapTable('load', resp.data);
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        self.$data.mytable.bootstrapTable('hideLoading');
        $(self.$data.mytable).unblock();
      });

    },
    modifyVoucher(id){
      const self = this;
      try {

        const requestOptions = {
          method: 'GET',
          mode: "cors",
          headers: userService.authHeader()
        };

        self.$data.labour = JSON.parse('{"id":0,"type":1,"name":"","mobile":""}');
        // self.$data.labour.id = id;
        // alert(`${process.env.VUE_APP_ROOT_API}api/labour/${id}`);
        fetch(`${process.env.VUE_APP_ROOT_API}api/bms/labour/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {

          if (resp.ok) {

            self.$data.labour = resp.data;
            self.$modal.show('labour_window');
          } else {
            swal({ title: "Oops!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oh noes!", text: err.toString(), type: "error" });
        });
      }catch (e) {
        alert(e);
      }
    },
    cancelVoucher (id) {
      const self = this;
      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      try {
        self.$data.labour = JSON.parse(JSON.parse('{"id":0,"name":"","print_name":""}'));
        self.$data.labour.id = id;

        swal({
          title: 'What is the reason for cancel this labour?',
          input: 'text',
          inputPlaceholder: 'Enter Remarks here',
          showCancelButton: true,
          inputClass: 'form-control',
          inputValidator: function (value) {
            return !value && 'You need to write something!'
          }
        }).then(function (result) {
          if (result.value) {

            self.$data.labour.remarks = result.value;

            const requestOptions = {
              method: 'DELETE',
              mode: 'cors',
              headers: userService.authHeader(),
              body: JSON.stringify(self.$data.labour)
            };

            fetch(`${process.env.VUE_APP_ROOT_API}api/bms/labour?remarks=${result.value}`, requestOptions).then(userService.handleResponse).then(function (resp) {
              self.$modal.hide('labour_window');
              if (resp.ok) {
                self.loadData();
                swal({title: 'Canceled!',  text: 'Your request has been processed', type: 'success'  });
              } else {
                swal({ title: "Oops", text: resp.msg, type: "info" });
              }
              $('#mycard').unblock();

            }).catch(function (err) {
              $('#mycard').unblock();
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }else {
            $('#mycard').unblock();
          }
        });

      }catch (e) {
        $('#mycard').unblock();
      }finally {

      }
    },
  }
}

</script>

<style scoped>
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;
  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
